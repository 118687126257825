import request from '../../../../../../utils/request';
import Form, { formCreate } from '../../../../../../components/form';
// import BudgetSubjectTable from '../components/budget_subject_table.vue';
// import CustomerList from '../components/customer-list.vue';
// import CompanyList from '../components/company-list.vue';

// formCreate.component('BudgetSubjectForm', BudgetSubjectTable);
// formCreate.component('CustomerList', CustomerList);
// formCreate.component('CompanyList', CompanyList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20230627000000002',
      formFunctionCode: 'return_factory_form',
      buttonText: {
        submit: '提交',
      },
      buttons: {
        submit: this.formConfig.code !== 'getDetail',
        // submitAudit: this.formConfig.code !== 'view',
      },
    };
  },
  methods: {
    // 设置rule
    // setRule(item) {
    //   const v = item;
    //   if (v.field === 'tpmActCode') { // 选择客户
    //     v.props = {
    //       ...v.props,
    //       disabled: false, // 是否禁用

    //     };
    //   } else if (v.field === 'companyName') { // 选择公司主体
    //     v.props = {
    //       ...v.props,
    //       params: {
    //         functionCode: 'tpm_company_list',
    //         paramData: {
    //           enableStatus: '009',
    //         },
    //       },
    //       getValue: (field) => this.getFieldValue(field),
    //       title: v.title,
    //     };
    //     v.on = {
    //       ...v.on,
    //       change: (e) => {
    //         this.setValue({
    //           companyName: e[0].companyName,
    //           companyCode: e[0].companyCode,
    //         });
    //       },
    //     };
    //   } else if (v.field === 'tpmAwardDetailReqVo') {
    //     v.props = {
    //       ...v.props,
    //     };
    //     v.value = {
    //       data: [],
    //     };
    //   } else if (v.field === 'totalAmount') {
    //     v.props = {
    //       ...v.props,
    //       min: 0,
    //       precision: 2,
    //       // controls: false,
    //     };
    //   }
    //   return v;
    // },
    // 表单渲染完成后回调
    formComplete() {
      this.disabled(true, ['tpmActCode', 'tpmActName', 'tpmActDetailCode', 'tpmActDetailName', 'actCreateName', 'actChargeName', 'createName', 'actItems']);
      if (this.formConfig.code === 'getDetail') {
        this.disabled(true, ['receptionName', 'receptionMobile', 'agencyName', 'agencyMobile', 'fee', 'ext2', 'ext3']);
      }
      if (this.formConfig.code === 'messge_apply') {
        this.fApi.hidden(true, ['fee', 'ext2', 'ext3']);
      }
      if (this.formConfig.code === 'amount_apply') {
        this.disabled(true, ['receptionName', 'receptionMobile', 'agencyName', 'agencyMobile']);
      }
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view' || this.id || this.formConfig.code === 'apply' || this.formConfig.code === 'getDetail' || this.formConfig.code === 'messge_apply' || this.formConfig.code === 'amount_apply') {
        const url = this.formConfig.code === 'view' ? '/tpm/tpmactdetailreturn/queryById' : '/tpm/tpmactdetailreturn/query';
        const params1 = {
          tpmActCode: this.formConfig.row.actCode,
          tpmActDetailCode: this.formConfig.row.actDetailCode,
        };
        const params2 = {
          id: this.formConfig.row ? this.formConfig.row.id : this.id,
        };
        const params = this.formConfig.code === 'view' ? params2 : params1;
        request
          .post(url, params)
          .then((res) => {
            if (res.success) {
              const data = res.result;
              // request
              //   .post('/tpm/tpmFeeBudgetController/chooseControl', {
              //     feeBudgetCode: data.detailRespVo.budgetCode,
              //   })
              //   .then((res1) => {
              //     if (res1.success) {
              //       const data1 = res1.result.data;
              //       data.tpmAwardDetailReqVo = { data: data1 || [] };
              //       const tpmAwardDetailReqVo = this.getRule('tpmAwardDetailReqVo');
              //       tpmAwardDetailReqVo.value = data.tpmAwardDetailReqVo;
              //     }
              //   });
              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit(e) {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        // if (formData.tpmAwardDetailReqVo) {
        //   if (formData.tpmAwardDetailReqVo.data[0].canUseAmount < formData.totalAmount) {
        //     this.$message.error('申请金额不用大于预算信息可用余额！');
        //     return false;
        //   }
        // }
        // const tpmAwardDetailReqVo = formData.tpmAwardDetailReqVo.data;
        // formData.tpmAwardDetailReqVo = {
        //   budgetCode: tpmAwardDetailReqVo[0].feeBudgetCode,
        //   budgetName: tpmAwardDetailReqVo[0].feeBudgetTypeName,
        //   budgetSubjectsCode: tpmAwardDetailReqVo[0].budgetSubjectsCode,
        //   budgetSubjectsName: tpmAwardDetailReqVo[0].budgetSubjectsName,
        // };
        console.log(formData);
        let url = '/tpm/tpmactdetailreturn/save';
        const params = { ...formData };
        delete params.titel1;
        delete params.title2;
        params.businessId = this.formConfig.row.id;
        console.log(params);
        if (this.formConfig.code === 'edit' || this.formConfig.code === 'amount_apply') {
          url = '/tpm/tpmactdetailreturn/update';
          // params.id = this.formConfig.row.id;
        }

        // if (e === 2) {
        //   this.$emit('submit', {
        //     row: params,
        //     submitUrl: url,
        //   });
        // } else {
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message.success('操作成功');
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
        // }
      }
    },
  },
};
